import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';

import { ClubsAPI } from 'api/clubs';
import { ReactComponent as Chevron } from 'assets/img/icons/faChevronRight.svg';
import LogoGrey from 'assets/img/LogoSotaGrey.svg';
import './index.css';

const TeamHeaderBlock = () => {
  const [t] = useTranslation();
  const { teamId } = useParams();
  const [lineup, setLineup] = useState([]);
  const [teamData, setTeamData] = useState<any>();

  useEffect(() => {
    const getLinup = async () => {
      if (teamId) {
        setLineup([]);
        setTeamData([]);
        const lineupResponse = await ClubsAPI.getLineup(
          teamId,
          null,
          'current',
        );
        setLineup(
          lineupResponse.data.sort((a: any, b: any) => a.number - b.number),
        );
        const teamResponse = await ClubsAPI.getTeam(teamId);
        setTeamData(teamResponse.data);
        const metaElement = document.getElementById('meta-top-name');
        const titleElement = document.getElementById('title-top-name');
        if (metaElement && titleElement) {
          metaElement.setAttribute('content', teamResponse.data.name);
          titleElement.innerHTML = teamResponse.data.name;
        }
      }
    };
    getLinup().then();
  }, [teamId]);
  const [showAll, setShowAll] = useState(false);
  const isVisible = (index: number) => {
    if (showAll) {
      return true;
    }
    return index < 19;
  };
  const c = () => {
    setShowAll(true);
    setRowsCount(Math.ceil(lineup.length / 5));
  };
  const navigate = useNavigate();
  const [rowsCount, setRowsCount] = useState(5);
  return (
    <div className="team-header-block flex-row f-ga-24">
      <div className="team-header-logo">
        <img src={teamData?.image || LogoGrey} alt={'Team logo'} />
        <div className="team-header-name">{teamData?.name}</div>
        {/* <div className="team-header-league">{teamData?.league?.name}</div> */}
      </div>
      <div
        className="block-wrapper team-header-lineup"
        style={{
          gridTemplateRows: `repeat(${rowsCount}, 1fr)`,
        }}
      >
        {lineup.map((player: any, index: number) => (
          <div
            key={index}
            className="team-header-player flex-row enabledClickable"
            style={{
              display: isVisible(index) ? 'flex' : 'none',
            }}
            onClick={() => {
              navigate('/players/' + player.id);
            }}
          >
            <div className="team-header-player-number">{player.number}</div>
            <div className="team-header-player-name">
              {player.first_name.slice(0, 1)}. {player.last_name}
            </div>
          </div>
        ))}
        {lineup.length > 20 && !showAll && (
          <div
            className="team-header-player flex-row see-more enabledClickable"
            onClick={() => c()}
          >
            <Chevron
              style={{
                transform: 'rotate(90deg)',
                width: 16,
                height: 16,
                marginLeft: 12,
              }}
            />
            <div
              className={'elipsis-text'}
              style={{ textWrap: 'nowrap', overflow: 'hidden' }}
            >
              {t('Show all players')}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default TeamHeaderBlock;

import { ru, pt, enGB } from 'date-fns/locale';

import i18n from '../i18n';

const t = i18n.t;
export const SELF_HOST = process.env.REACT_APP_SELF_HOST;
export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const ENV = process.env.REACT_APP_ENV;
// console.log('API_ENDPOINT', API_ENDPOINT);
// console.log('API_ENDPOINT', process.env);
export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export const MONTHS_SHORT = [
  'jan',
  'feb',
  'mar',
  'apr',
  'may',
  'jun',
  'jul',
  'aug',
  'sep',
  'oct',
  'nov',
  'dec',
];
export const MONTHS_ENDING = [
  // t("January-a"),
  // t("February-a"),
  // t("March-a"),
  // t("April-a"),
  // t("May-a"),
  // t("June-a"),
  // t("July-a"),
  // t("August-a"),
  // t("September-a"),
  // t("October-a"),
  // t("November-a"),
  // t("December-a")
  '01.01',
  '01.02',
  '01.03',
  '01.04',
  '01.05',
  '01.06',
  '01.07',
  '01.08',
  '01.09',
  '01.10',
  '01.11',
  '01.12',
];
export const MONTHS_ENDING_ru = [
  // "Января",
  // "Февраля",
  // "Марта",
  // "Апреля",
  // "Мая",
  // "Июня",
  // "Июля",
  // "Августа",
  // "Сентября",
  // "Октября",
  // "Ноября",
  // "Декабря"
  '31.01',
  '28.02',
  '31.03',
  '30.04',
  '31.05',
  '30.06',
  '31.07',
  '31.08',
  '30.09',
  '31.10',
  '30.11',
  '31.12',
];
export const GAME_TYPE_ABBREVIATIONS: any = {
  home: t('H'),
  away: t('A'),
};

export const GLOBAL_TEAMS = [
  { id: 1, name: 'Abc', created_at: '', updated_at: '', image: null, club: 1 },
  { id: 2, name: 'Bcd', created_at: '', updated_at: '', image: null, club: 1 },
  { id: 3, name: 'Cde', created_at: '', updated_at: '', image: null, club: 1 },
  { id: 4, name: 'Efg2', created_at: '', updated_at: '', image: null, club: 1 },
  { id: 5, name: 'Efg3', created_at: '', updated_at: '', image: null, club: 1 },
  { id: 6, name: 'Efg4', created_at: '', updated_at: '', image: null, club: 1 },
  { id: 7, name: 'Efg5', created_at: '', updated_at: '', image: null, club: 1 },
  { id: 8, name: 'Efg6', created_at: '', updated_at: '', image: null, club: 1 },
  { id: 9, name: 'Efg7', created_at: '', updated_at: '', image: null, club: 1 },
];
export const CITIES = [
  { id: 1, name: 'Abc (UTC+1)' },
  { id: 2, name: 'Bcd (UT+1)' },
  { id: 3, name: 'Cde (UT+1)' },
  { id: 4, name: 'Efg2 (UT+1)' },
  { id: 5, name: 'Efg3 (UT+1)' },
  { id: 6, name: 'Efg4 (UT+1)' },
  { id: 7, name: 'Efg5 (UT+1)' },
  { id: 8, name: 'Efg6 (UT+1)' },
  { id: 9, name: 'Efg7 (UTC+1)' },
];
export const CAMERAS = [
  {
    id: '1',
    name: 'Поле 31',
    status: {
      isRecording: false,
      recordingTime: null,
      readyToRecord: true,
      spaceLeftHours: 12,
      totalSpaceHours: 12,
    },
  },
  {
    id: '2',
    name: 'Поле 32',
    status: {
      isRecording: true,
      recordingTime: '00:00:12',
      readyToRecord: true,
      spaceLeftHours: 12,
      totalSpaceHours: 12,
    },
  },
  {
    id: '3',
    name: 'Поле 33',
    status: {
      isRecording: false,
      recordingTime: null,
      readyToRecord: true,
      spaceLeftHours: 12,
      totalSpaceHours: 12,
    },
  },
  {
    id: '4',
    name: 'Поле 34',
    status: {
      isRecording: false,
      recordingTime: null,
      readyToRecord: true,
      spaceLeftHours: 12,
      totalSpaceHours: 12,
    },
  },
  {
    id: '5',
    name: 'Поле 35',
    status: {
      isRecording: false,
      recordingTime: null,
      readyToRecord: true,
      spaceLeftHours: 12,
      totalSpaceHours: 12,
    },
  },
];
export const WINDOW_MODE_WIDTH = 5;
export const WINDOW_MODE_WIDTH_MS = WINDOW_MODE_WIDTH * 1000;
export const TIMELINE_ANIMATION_STEP_MS = 200;
export const EDIT_VIDEO_WINDOW_WIDTH_MS = 60 * 3 * 1000;
export const MAX_UPLOAD_VIDEO_SIZE = 6048 * 1024 * 1024; // Mbytes
export const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mmZ';
export const STYLE_TYPES = {
  light: 'light',
  dark: 'dark',
};
export const LANGUAGES: any = {
  ru: 'Русский',
  // pt: 'Português',
  en: 'English',
};
export const MINIMAL_USER_EPISODE_LENGTH_SECONDS = 1;
export const DEFAULT_USER_EPISODE_LENGTH_SECONDS = 20;
export const VIDEO_TIME_MAX_ZOOM = 1000;

export const CALENDAR_LOCALES: any = {
  ru: ru,
  pt: pt,
  en: enGB,
};
export const TIME_REGEX = /^(([0-9]{1,3}):{1,1})([0-5]{0,1}[0-9]{0,1})$/;
export const FINISHED_TIME_REGEX =
  /^(([0-9]{1,3}):{1,1})([0-5]{1,1}[0-9]{1,1})$/;
export const NEW_PLAYLIST_TEXT = 'New playlist';
export const NEW_FOLDER = 'New folder';
export const REELS_DURATION_WARNING_THRESHOLD_MS = 60 * 1000;
export const PLAYER_ROLES_ABBREVIATED: any = {
  [t('All positions')]: t('All positions'),
  'central midfield': 'CM',
  goalkeeper: 'GK',
  forward: 'FW',
  'centre forward': 'CF',
  defender: 'DF',
  'left defender': 'LD',
  'right defender': 'RD',
  'central defender': 'CD',
  midfielder: 'MD',
  'central midfielder': 'CM',
  'defensive midfielder': 'DM',
  'left midfielder': 'LM',
  'right midfielder': 'RM',
  'attacking midfielder': 'AM',
  вратарь: 'GK',
  нападающий: 'FW',
  'центральный нападающий': 'CF',
  защитник: 'DF',
  'левый защитник': 'LD',
  'правый защитник': 'RD',
  'центральный защитник': 'СD',
  полузащитник: 'MD',
  'центральный полузащитник': 'CM',
  'опорный полузащитник': 'DM',
  'левый полузащитник': 'LM',
  'правый полузащитник': 'RM',
  'атакущий полузащитник': 'AM',
};
export const EPISODES_OTHER = 'episodes-other';

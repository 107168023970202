import { API_ENDPOINT } from 'types/constants';
import myAxios from 'utils/axios';

import { addParams } from './events';

const getTimeZones = async (zoneName: string) => {
  const url = `${API_ENDPOINT}api/registers/v1/timezones/`;
  try {
    return await myAxios.GET(url, { search: zoneName });
  } catch (e: any) {
    return e.response.data;
  }
  return {};
};
const getTimeZonesV2 = async (
  searchString: string,
  cityName?: string,
  zoneName?: string,
  cityId?: string,
) => {
  const url = `${API_ENDPOINT}api/registers/v2/timezones`;
  const params = new URLSearchParams();
  params.append('search', searchString);
  // console.log('cityName', cityName, 'zoneName', zoneName)
  if (cityName) {
    params.append('city', cityName);
  }
  if (zoneName) {
    params.append('zone', zoneName);
  }
  if (cityId) {
    params.append('city_id', cityId);
  }
  try {
    return await myAxios.GET(url, params);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
const getTournaments = async (params?: any, team?: number) => {
  const url = `${API_ENDPOINT}api/registers/tournaments/`;
  let query = new URLSearchParams();
  query = addParams(params, query);
  if (team) {
    query.append('team', team.toString());
  }
  try {
    return await myAxios.GET(url, query);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
const getSeasons = async (tournament: number) => {
  const url = `${API_ENDPOINT}api/registers/seasons/`;
  try {
    return await myAxios.GET(url, { tournament: tournament });
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
const getScoreTable = async (
  tournament: number,
  season: number,
  seasonPart: number | 'null',
) => {
  const url = `${API_ENDPOINT}api/registers/seasons/${season}/score_table/`;
  try {
    const params = new URLSearchParams();
    params.append('tournament', tournament.toString());
    params.append('season', season.toString());
    if (seasonPart && seasonPart !== 'null') {
      params.append('seasonPart', seasonPart.toString());
    }
    return await myAxios.GET(url, params);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
export const RegistriesAPI = {
  getTimeZones,
  getTimeZonesV2,
  getTournaments,
  getSeasons,
  getScoreTable,
};

import React, { FC, useEffect } from 'react';

import { playerReducerV2 } from 'reducers/player.reducer';
import { reelsReducer } from 'reducers/reels.reducer';
import { teamReducer } from 'reducers/team.reducer';
import { teamPlayerReducer } from 'reducers/teamPlayer.reducer';
import { teamPlayerStatsSectionReducer } from 'reducers/teamPlayerStatsSection.reducer';

import BestPlayersBlock from './BestPlayersBlock';
import MatchesBlock from './MatchesBlock';
import StatisticsBlock from './StatisticsBlock';
import './index.css';
import TournamentTableBlock from './TournamentTableBlock';
import {
  AC_AddSelectedTournamentFilterAction,
  AC_SelectedMatchesFilterAction,
  AC_SetLastXFilterAction,
  AC_SetSelectedAttributes,
} from '../../actions/filter.actions';
import { setStyleModeAction } from '../../actions/interface.actions';
import {
  AC_GetVideoEpisodesSuccess,
  AC_SetActiveVideoAction,
  AC_SetSelectedGames,
  AC_SetVisibleRangeAction,
} from '../../actions/player.acitons';
import { useAppDispatch } from '../../store';
import GameProtocolSidePanel from '../GameProtocolSidePanel';
import GamesSelectionModal from '../modals/GamesSelectionModal';

export const TeamPageController: FC<any> = () => {
  const dispatch = useAppDispatch();
  const { setStatsCustomGameList } = teamReducer.actions;
  const { resetReelsState } = reelsReducer.actions;
  const { setOpenedPlayer } = teamPlayerReducer.actions;
  const { resetEditMode } = playerReducerV2.actions;
  const { setSelectedLastMatch_stats, setSelectedMetricFilters } =
    teamPlayerStatsSectionReducer.actions;
  useEffect(() => {
    dispatch(setStyleModeAction('light'));
    dispatch(setStatsCustomGameList([]));
    dispatch(AC_GetVideoEpisodesSuccess([]));
    dispatch(AC_SetLastXFilterAction(null));
    dispatch(AC_SetSelectedAttributes([]));
    dispatch(AC_AddSelectedTournamentFilterAction([]));
    dispatch(AC_SetSelectedGames([]));
    dispatch(AC_SelectedMatchesFilterAction([]));
    dispatch(AC_SetActiveVideoAction(null));
    dispatch(resetEditMode());
    dispatch(setSelectedLastMatch_stats(''));
    dispatch(setSelectedMetricFilters([]));
    dispatch(AC_SetVisibleRangeAction([]));
    dispatch(resetReelsState());
    dispatch(setOpenedPlayer(null));
    window.document.body.setAttribute('data-theme', 'light');
  }, []);
  return (
    <>
      <div className="team-page-controller">
        <div className="flex-column f-ga-32" style={{ flex: 1 }}>
          <StatisticsBlock />
          <MatchesBlock />
        </div>
        <div className="flex-column f-ga-24">
          <BestPlayersBlock />
          <TournamentTableBlock />
        </div>
        <GameProtocolSidePanel />
        <GamesSelectionModal />
      </div>
    </>
  );
};

import React, { useEffect, useState } from 'react';

import { Select } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { ClubsAPI } from 'api/clubs';
import { RegistriesAPI } from 'api/registries';
import LogoGrey from 'assets/img/LogoSotaGrey.svg';
import './index.css';
import Loader from 'components/Loader';

const TournamentTableBlock = () => {
  const [t] = useTranslation();
  const { teamId } = useParams();
  const [selectedSeason, setSelectedSeason] = useState<any>(null);
  const [seasons, setSeasons] = useState<any>([]);
  const [fullSeasons, setFullSeasons] = useState<any[]>([]);
  const [stats, setStats] = useState([]);
  const [tournamentIsLoading, setTournamentIsLoading] = useState(false);
  useEffect(() => {
    const getLinup = async () => {
      if (teamId) {
        setSeasons([]);
        const seasonsResponse = await ClubsAPI.getTeamSeasons(teamId, true);
        const seasonsList = seasonsResponse.data;
        if (seasonsList !== undefined) {
          setTournamentIsLoading(true);
          setFullSeasons(seasonsList);
          const s = seasonsList.map((season: any) => {
            const startYear = dayjs(season.start_date).format('YYYY');
            const endYear = dayjs(season.end_date).year().toString();
            let label: string;
            if (startYear === endYear) {
              label = `${season.tournament.name} ${startYear}`;
            } else {
              label = `${season.tournament.name} ${startYear}/${endYear}`;
            }
            return season.tournament_tables.map((table: any) => {
              return {
                label: `${label} ${table.part_id ? table.part_name : ''}`,
                value: `${season.tournament.id}-${season.id}-${table.part_id}`,
              };
            });
          });
          if (s[0].length) {
            setSelectedSeason(s[0][0]);
            setSeasons(s[0]);
          }
          if (seasonsResponse?.data?.length) {
            const teamResponse = await RegistriesAPI.getScoreTable(
              seasonsList[0].tournament.id,
              seasonsList[0].id,
              seasonsList[0]?.tournament_tables[0]?.part_id,
            );
            if (teamResponse.data !== undefined) {
              setStats(teamResponse.data);
            } else {
              setStats([]);
            }
          } else {
            setStats([]);
          }
          setTournamentIsLoading(false);
        }
      }
    };
    getLinup().then();
  }, [teamId]);
  useEffect(() => {
    const getTournamentTable = async () => {
      if (selectedSeason && selectedSeason.value) {
        setTournamentIsLoading(true);
        const teamResponse = await RegistriesAPI.getScoreTable(
          selectedSeason?.value.split('-')[0],
          selectedSeason?.value.split('-')[1],
          selectedSeason?.value.split('-')[2] || '',
        );
        if (teamResponse.data !== undefined) {
          setStats(teamResponse.data);
        }
        setTournamentIsLoading(false);
      }
    };
    getTournamentTable().then();
  }, [selectedSeason]);
  // const navigate = useNavigate();
  const getTournamentImage = () => {
    return fullSeasons.filter(
      (fullSeason: any) =>
        fullSeason.id === Number(selectedSeason?.value?.split('-')[1]),
    )[0]?.tournament?.image;
  };
  return (
    <div className="block-wrapper right f-ga-8">
      <div className="tournament-header">
        <img src={getTournamentImage() || LogoGrey} alt="Tournament logo" />
        <div>{selectedSeason?.label}</div>
        {seasons.length > 1 ? (
          <Select
            // style={{minWidth: 130}}
            variant="borderless"
            value={selectedSeason.value}
            onChange={(value: any) => {
              setSelectedSeason(
                seasons.filter((s: any) => s.value === value)[0],
              );
            }}
            popupMatchSelectWidth={false}
          >
            {seasons.map((season: any, index: number) => (
              <Select.Option
                key={index}
                label={season.value}
                value={season.value}
              >
                {season.label}
              </Select.Option>
            ))}
          </Select>
        ) : (
          seasons[0]?.label
        )}
      </div>
      {!tournamentIsLoading ? (
        <>
          <div className="tournament-table-header">
            <div>{t('#')}</div>
            <div style={{ flex: '1 0 0' }}>{t('Team')}</div>
            <div
              className="flex-row f-ga-8 j-sb"
              style={{ minWidth: 230, textAlign: 'center' }}
            >
              <div style={{ flex: 1, width: 20 }}>{t('M-header')}</div>
              <div style={{ flex: 1, width: 20 }}>{t('W-header')}</div>
              <div style={{ flex: 1, width: 20 }}>{t('D-header')}</div>
              <div style={{ flex: 1, width: 20 }}>{t('L-header')}</div>
              <div style={{ width: 38 }}>{t('G-header')}</div>
              <div style={{ flex: 1, width: 20 }}>{t('GD-header')}</div>
              <div style={{ flex: 1, width: 20 }}>{t('P-header')}</div>
            </div>
          </div>
          {stats.map((team: any, index: number) => (
            <div
              onClick={() => {
                // navigate(`/teams/${team.id}`);
              }}
              key={index}
              className={`tournament-team-wrapper ${team.id.toString() === teamId?.toString() ? 'active' : ''}`}
            >
              <div className="tournament-team-position">{index + 1}</div>
              <div className="tournament-team-logo">
                <img src={team.logo || LogoGrey} alt={'Team Logo'} />
              </div>
              <div className="tournament-team-name">{team.name}</div>
              <div className="tournament-team-stats">
                <div style={{ flex: 1, width: 20 }}>{team.matches}</div>
                <div style={{ flex: 1, width: 20 }}>{team.wins}</div>
                <div style={{ flex: 1, width: 20 }}>{team.draws}</div>
                <div style={{ flex: 1, width: 20 }}>{team.losses}</div>
                <div style={{ width: 38 }}>{team.goals}</div>
                <div style={{ flex: 1, width: 20 }}>{team.rg}</div>
                <div style={{ flex: 1, width: 20 }}>{team.points}</div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <Loader color="black" />
      )}
    </div>
  );
};
export default TournamentTableBlock;
